<template>
  <div class="editOrderBox">
    <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant'))?{
          background: '#fff',
          color: '#13161B',
        }: {
          background: 'var(--themeColor)',
          color: '#fff',
        }"
      :backEvent="true"
      @back-event="closeWebview()"
      :back="true"
    >
    </iHeader>
    <div class="publicHead">
      <p>预算列表</p>
    </div>
    <ScrollBox
      class="opOrderListScroll"
      @on-top="scrollTop"
      @on-bottom="scrollBottom"
      :loadType="loadType"
    >
      <template v-slot:scrollBox>
        <div v-if="dataList.length" class="contentBox">
            <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div class="itemHeadBox">
              <div class="budgetItem_title">{{item.SourceCodeTxt||'聚合网约车'}}</div>
              <div class="budgetItem">
                <div>
                  <svg class="icon-normal" aria-hidden="true">
                      <use xlink:href="#icon-weizhi" />
                  </svg>
                  {{item.Addr_start}}
                </div>
                <div class="BudgetAmount">预计总金额<i class="f16">{{item.BudgetAmount||0|currencyFormatter}}</i></div>
              </div>  
            </div>
            <div class="btnList" v-if="checkCanNewItem.show&&item.UserId==$cookies.get('userId')">
              <a class="btnLength2" @click.stop="isloding && deleteItemP(item)">
                删除</a
              >
              <a
                class="btnLength2"
                @click.stop="isloding && goAddPlatform(item)"
              >
                编辑</a
              >
            </div>
          </li>
            </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <AddButton
      v-if="!SmartStorage.get('viewOnly') && checkCanNewItem.show"
      :color="$publicFun.setColor()"
      @click="goAddPlatform('')"
    ></AddButton>
    <MessageBox
      ref="tipsMsg"
      type="tips"
      :messageTxt="messageTxt"
      @on-ok="MessageBtn()"
    ></MessageBox>
  </div>
</template>

<script>
import filterEmoji from "@/views/controllers/filterEmoji.js";
import submitOrder from "@/views/controllers/submitOrder.js";
export default {
  mixins: [submitOrder, filterEmoji],
  data() {
    return {
      clickItem:{},
      total: 0,
      loadType: "",
      pageIndex: 1,
      pageSize: 10,
      dataList: [],
      isFirst: true,
      messageType: "",
      messageTxt: "",
      checkCanNewItem: {},
      skList: [
        {
          width: "65%",
          row: 5,
        },
        {
          width: "20%",
          position: "right",
        },
        {
          width: "40%",
          inline: true,
        },
        {
          width: "50%",
        },
        {
          width: "30%",
          ml: ".1rem",
        },
        {
          width: "15%",
          inline: true,
        },
        {
          width: "100%",
          inline: true,
        },
        {
          width: "0",
          inline: true,
        },
      ],
    };
  },
  created() {
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on("closeWebview", (data) => {
      this.closeWebview();
    });
    !this.SmartStorage.get("viewOnly") && this.checkItem();
    this.StatByStatus(true);
  },
  methods: {
    refrashData(){
      this.StatByStatus(true);
    },
      deleteItemP(data){
        console.log(data)
          // this.clickItem=data;
          // this.clickItem.itemId=data.ItemId;
          this.deleteItem(data);
      },
      goAddPlatform(data) {
      this.$router.push({
        path: "/addPlatform",
        query: {
          isPlantform: true,
          data:data
        }
      });
    },
    //是否可以添加需求
    async checkItem() {
      let param = {
        proposalId: this.SmartStorage.get("proposalId"),
        isOnline: true,
        sourceCode: "",
      };
      let _this = this;
      this.services
        .CheckCanNewItem(param)
        .then(function (res) {
          _this.checkCanNewItem = res.content;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    redirectRouteListeners() {
      this.SmartStorage.set("routeListeners", ["closeWebview"]);
    },
    // 退出城市车队页面
    closeWebview() {
      const container = this.$cookies.get("container");
      switch (container) {
            case "browser":
                this._browserBack()
                break;
            case "h5":
                this._h5Back()
                break;
            case "native":
            default:
              this.closeAppWithReload();
      }
    },
    scrollTop(filter) {
      this.loadType = "top";
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.StatByStatus(true);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = "none";
      } else {
        this.loadType = "bottom";
        this.pageIndex += 1;
        this.StatByStatus();
      }
    },
    async StatByStatus(clear) {
      let params = {
        Channel: 1,
        Filter: {
          ProposalId: this.SmartStorage.get("proposalId"),
        },
        pagerIndex: this.pageIndex,
        pagerSize: this.pageSize,
        Projection: { _id: 0, CreatedOn: 0 },
        Sort: null,
      };
      params.isAuthorized =
        (this.$cookies.get("tenant") == "msd" ||
        this.SmartStorage.get("eventData").isFromMore)
          ? true
          : false;
      let _this = this;
      this.services.Paging(params).then(function (res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          for (let i = 0; i < eventList.length; i++) {
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = "";
          _this.isloding = true;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/platform/index.scss'
</style>